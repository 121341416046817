<template>
    <div>
        <h2 v-if="name">{{ name }}</h2>
        <div class="gallery">
        <div
            v-for="item in items"
            :key="item.id"
            class="gallery__item"
        >
            <router-link
                :to="getLink(item)"
                custom
                v-slot="{ navigate }"
            >
                <div
                    class="gallery__image-wrap"
                    :class="{ '-is-album': item.imageCount }"
                    @click="navigate"
                    role="link"
                >
                    <template v-if="item.thumbnail">
                        <img
                            class="gallery__image"
                            :src="item.thumbnail"
                        />
                        <div class="gallery__image-overlay" v-if="item.imageCount">
                            <h3 class="h3 gallery__album-meta">{{ item.name }}</h3>
                            <div class="gallery__album-meta">{{ item.imageCount }} images</div>
                            <div class="gallery__album-meta">{{ item.createdAt }}</div>
                        </div>
                    </template>
                    <loading-spinner
                        v-else
                        theme="dark"
                    />
                </div>
            </router-link>
        </div>
    </div>
    </div>
</template>

<script>
import LoadingSpinner from './LoadingSpinner.vue';

export default {
    name: 'Gallery',
    components: { LoadingSpinner },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        name: {
            type: String,
            default: '',
        },
    },
    methods: {
        getLink({ imageCount, id }) {
            return imageCount
                ? { name: 'dashboard.albums.show', params: { albumId: id } }
                : { name: 'dashboard.albums.image.show', params: { imageId: id } };
        },
    },
};
</script>

<style lang="scss" scoped>
.gallery {
    $scope: &;

    &__image {
        width: 100%;
        vertical-align: bottom;
        transition: transform .5s;
        border-radius: var(--border-radius);
    }

    &__image-wrap {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        svg {
            @include size(6rem);
        }

        &.-is-album {
            &:hover {
                #{$scope} {
                    &__image {
                        transform: scale(1.05);
                    }

                    &__image-overlay {
                        visibility: visible;
                        opacity: 1;
                    }

                    &__album-meta {
                        transform: translateY(0);

                        &:nth-child(2) {
                            transition-delay: .1s
                        }

                        &:nth-child(3) {
                            transition-delay: .15s
                        }

                        &:nth-child(4) {
                            transition-delay: .2s
                        }
                    }
                }
            }
        }
    }

    &__image-overlay {
        @include absolute(0, 0, 0, 0);

        background-color: var(--image-overlay-bg-color);
        color: var(--image-overlay-font-color);
        pointer-events: none;
        visibility: hidden;
        opacity: 0;
        transition: visibility .1s, opacity .3s;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &__album-meta {
        transform: translateY(2rem);
        transition: transform .5s;
        margin-bottom: 1rem;

        &.h3 {
            margin-bottom: 2rem;
        }
    }

    &__item {
        margin-bottom: 2rem;
        border-radius: var(--border-radius);
        cursor: pointer;
        overflow: hidden;
    }
}

@include tablet {
    .gallery {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-gap: 2rem;

        &__item {
            margin-bottom: 0;
        }
    }
}

@include desktop {
    .gallery {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@include desktop2 {
    .gallery {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}
</style>
