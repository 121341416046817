<template>
    <page :heading="`Edit ${album.name}`">
        <content-area>
            <gallery :items="album.images" />
        </content-area>

        <content-area>
            <image-uploader
                v-model="images"
                :album-id="albumId"
            />

            <v-button @click="addImagesToAlbum">
                Add Images
            </v-button>
        </content-area>
    </page>
</template>

<script>
import { mapState } from 'vuex';
import store from '../../store';
import Page from '../../layouts/Page.vue';
import ContentArea from '../../components/Presentation/ContentArea.vue';
import Gallery from '../../components/Presentation/Gallery.vue';
import ImageUploader from '../../components/Action/ImageUploader/ImageUploader.vue';
import VButton from '../../components/Action/VButton.vue';
import to from '../../plugins/to';
import api from '../../plugins/api';
import echo from '../../plugins/echo';

export default {
    components: {
        VButton,
        ImageUploader,
        Gallery,
        ContentArea,
        Page,
    },
    async beforeRouteEnter({ params }) {
        const { albumId: id } = params;

        await store.dispatch('album/fetchAlbum', { id });
    },
    mounted() {
        this.albumId = this.$route.params.albumId;

        // Subscribe to album thumbnail channel.  This channel will load in the thumbnails
        // once they are transformed since we're transforming them in a queued task.
        echo.private(`album.${this.albumId}.thumbnails`)
            .listen('AlbumThumbnailGenerated', (image) => {
                store.dispatch('album/addImage', { image });
            });
    },
    methods: {
        async addImagesToAlbum() {
            this.submitting = true;

            // const [err, res] = await to(api.patch(`/albums/${this.albumId}`, {
            await to(api.patch(`/albums/${this.albumId}`, {
                // name: this.name,
                images: this.images.map((image) => image.id),
                // categories: this.selectedCategories,
            }));

            this.submitting = false;
        },
    },
    computed: {
        ...mapState('album', ['album']),
    },
    data: () => ({
        images: [],
        submitting: false,
        albumId: null,
    }),
};
</script>
